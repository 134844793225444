<template>
  <div>
    <b-card title="Filtele">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Firma"
            label-for="id_customers"
          >
            <v-select
              v-model="reportQuery.id_customers"
              label="company"
              :options="customers"
              :reduce="customer => customer.id"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="reportQuery.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="reportQuery.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button
            :disabled="!reportQuery.id_customers || loadingReport"
            variant="primary"
            @click="setReport"
          >
            <template v-if="loadingReport">
              <b-spinner
                class="mr-1"
                variant="light"
                small
              />
              Yükleniyor..
            </template>
            <template v-else>
              <feather-icon icon="PieChartIcon" />
              Raporu Getir
            </template>

          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="report && report.serviceCount || report.MachinesCount">
      <b-col>
        <statistic-card
          :totals="report.ServiceCount"
          icon="CheckCircleIcon"
          statistic-title="Firma Servis Sayısı"
          color="success"
        />
      </b-col>
      <b-col>
        <statistic-card
          :totals="report.ServiceWorkTime.duration ? report.ServiceWorkTime.duration : 0"
          icon="ClockIcon"
          statistic-title="Firma Servis Çalışma Saati"
          color="info"
        />
      </b-col>
      <b-col>
        <statistic-card
          :totals="report.MachinesCount"
          icon="SettingsIcon"
          statistic-title="Firma Makine Sayısı"
          color="warning"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormDatepicker, BButton, BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import StatisticCard from '@/views/Reports/Customer_service_reports/Components/StatisticCard.vue'

export default {
  name: 'CustomerServiceReport',
  components: {
    StatisticCard,
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BSpinner,
  },
  data() {
    return {
      reportQuery: {
        sdate: null,
        edate: null,
        id_customers: null,
      },
      loadingReport: false,
    }
  },
  computed: {
    report() {
      return this.$store.getters['customerServiceReport/getReport']
    },
    customers() {
      return this.$store.getters['customers/getCustomers']
    },
  },
  created() {
    this.getCustomers()
    this.$store.commit('customerServiceReport/RESET_REPORT')
  },
  methods: {
    getCustomers() {
      this.$store.dispatch('customers/customersList')
    },
    setReport() {
      this.loadingReport = true
      this.$store.dispatch('customerServiceReport/setReport', {
        sdate: this.reportQuery.sdate,
        edate: this.reportQuery.edate,
        id_customers: this.reportQuery.id_customers,
      })
        .then(response => {
          this.loadingReport = false
        })
    },
  },
}
</script>
